import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ItemCard from '../components/ItemCard';
import api from '../api';
import './LensPage.css';

const categoryMap = {
    'Canon': 149,
    'Sony': 158,
    'Nikon': 154
};

// If the user chooses "All", we fetch all lens categories
const ALL_LENS_IDS = [149, 158, 154];

const LensPage = () => {
    const [items, setItems] = useState([]);
    const [searchTerm, setSearchTerm] = useState(''); // NEW
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const brand = params.get('brand') || 'All';

    useEffect(() => {
        const fetchLenses = async () => {
            setLoading(true);
            setError(null);

            try {
                let fetchedItems = [];

                if (brand === 'All') {
                    const requests = ALL_LENS_IDS.map(catId => api.get(`/items/category/${catId}`));
                    const responses = await Promise.all(requests);
                    fetchedItems = responses.flatMap(r => r.data);
                } else {
                    const categoryId = categoryMap[brand] || categoryMap['Canon'];
                    const response = await api.get(`/items/category/${categoryId}`);
                    fetchedItems = response.data;
                }

                const seen = new Map();
                for (const i of fetchedItems) {
                    if (!seen.has(i.itemId)) {
                        seen.set(i.itemId, i);
                    }
                }
                setItems([...seen.values()]);
            } catch (err) {
                console.error(err);
                setError('Failed to fetch lenses');
            } finally {
                setLoading(false);
            }
        };

        fetchLenses();
    }, [brand]);

    // Search logic
    const sTerm = searchTerm.toLowerCase().trim();
    const finalFilteredItems = items
        .filter(item => !item.hidden)
        .filter(item => {
            if (!sTerm) return true;

            const desc = (item.description || '').toLowerCase();
            const upc = (item.upc || '').toLowerCase();
            const ean = (item.ean || '').toLowerCase();
            const customSku = (item.customSku || '').toLowerCase();
            const manufacturerSku = (item.manufacturerSku || '').toLowerCase();

            return (
                desc.includes(sTerm) ||
                upc.includes(sTerm) ||
                ean.includes(sTerm) ||
                customSku.includes(sTerm) ||
                manufacturerSku.includes(sTerm)
            );
        });

    if (loading) return <p>Loading...</p>;
    if (error) return <p>{error}</p>;

    return (
        <div className="lens-page-container">
            <div className="lens-header">
                <h1>{brand === 'All' ? 'All Lenses' : `${brand} Lenses`}</h1>
                <input
                    type="text"
                    placeholder="Description or SKU..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="lens-search-input"
                />
            </div>

            <div className="items-grid">
                {finalFilteredItems.map(item => (
                    <ItemCard key={item.itemId} item={item} />
                ))}
            </div>
        </div>
    );
};

export default LensPage;