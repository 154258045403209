import React, { useEffect, useState } from 'react';
import ItemCard from '../components/ItemCard';
import api from '../api';
import './LightingPage.css';

const LightingPage = () => {
    const [lightingItems, setLightingItems] = useState([]);
    const [searchTerm, setSearchTerm] = useState(''); // NEW
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchLightingAccessoriesItems = async () => {
            try {
                const response = await api.get('/items/category/239');
                console.log("Fetched Lighting and Accessories:", response.data);
                setLightingItems(response.data);
            } catch (error) {
                setError('Failed to fetch Lighting and Accessories');
                console.error(error);
            } finally {
                setLoading(false);
            }
        };

        fetchLightingAccessoriesItems();
    }, []);

    // Filter logic
    const sTerm = searchTerm.toLowerCase().trim();
    const finalFilteredItems = lightingItems
        .filter(item => !item.hidden)
        .filter(item => {
            if (!sTerm) return true;

            const desc = (item.description || '').toLowerCase();
            const upc = (item.upc || '').toLowerCase();
            const ean = (item.ean || '').toLowerCase();
            const customSku = (item.customSku || '').toLowerCase();
            const manufacturerSku = (item.manufacturerSku || '').toLowerCase();

            return (
                desc.includes(sTerm) ||
                upc.includes(sTerm) ||
                ean.includes(sTerm) ||
                customSku.includes(sTerm) ||
                manufacturerSku.includes(sTerm)
            );
        });

    if (loading) return <p>Loading...</p>;
    if (error) return <p>{error}</p>;

    return (
        <div className="lighting-page-container">
            <div className="lighting-header">
                <h1>Lighting and Accessories</h1>
                <input
                    type="text"
                    placeholder="Description or SKU..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="lighting-search-input"
                />
            </div>

            <div className="items-grid">
                {finalFilteredItems.map(item => (
                    <ItemCard key={item.itemId} item={item} />
                ))}
            </div>
        </div>
    );
};

export default LightingPage;