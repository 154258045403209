// src/components/common/Header.js
import React, { useContext } from 'react';
import { Link, NavLink } from 'react-router-dom';
import AuthContext from '../../context/AuthContext';
import { Navbar, Nav, Container, Button, NavDropdown } from 'react-bootstrap';
import './Header.css';
import { CartContext } from '../../context/CartContext';

const Header = () => {
    const { user, logout } = useContext(AuthContext);

    // Access the cart from CartContext
    const { cart } = useContext(CartContext);

    return (
        <Navbar bg="dark" variant="dark" expand="lg">
            <Container>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    {user ? (
                        <>
                            <Nav className="me-auto">
                                {/* Cameras */}
                                <NavDropdown
                                    title="Cameras"
                                    id="camera-brand-dropdown"
                                >
                                    <NavDropdown.Item as={Link} to="/cameras?brand=All">
                                        All Cameras
                                    </NavDropdown.Item>

                                    <NavDropdown.Item as={Link} to="/cameras?brand=Canon">
                                        Canon
                                    </NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="/cameras?brand=Sony">
                                        Sony
                                    </NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="/cameras?brand=Nikon">
                                        Nikon
                                    </NavDropdown.Item>
                                </NavDropdown>


                                {/* Lenses */}
                                <NavDropdown
                                    title="Lenses"
                                    id="lens-brand-dropdown"
                                >
                                    <NavDropdown.Item as={Link} to="/lenses?brand=All">
                                        All Lenses
                                    </NavDropdown.Item>

                                    <NavDropdown.Item as={Link} to="/lenses?brand=Canon">
                                        Canon
                                    </NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="/lenses?brand=Sony">
                                        Sony
                                    </NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="/lenses?brand=Nikon">
                                        Nikon
                                    </NavDropdown.Item>
                                </NavDropdown>


                                <Nav.Link as={NavLink} to="/lightings">Lighting</Nav.Link>
                                <Nav.Link as={NavLink} to="/video-production">Accessories</Nav.Link>
                                <Nav.Link as={NavLink} to="/projectors">Projectors</Nav.Link>
                                <Nav.Link as={NavLink} to="/all-items">All Rental Items</Nav.Link>

                                {/* Admin Links */}
                                {user.roles.includes('ROLE_ADMIN') && (
                                    <NavDropdown title="Admin" id="admin-nav-dropdown">
                                        <NavDropdown.Item as={Link} to="/admin">Dashboard</NavDropdown.Item>
                                        <NavDropdown.Item as={Link} to="/admin/items">Manage Items</NavDropdown.Item>
                                        <NavDropdown.Item as={Link} to="/admin/store-info">Store Info</NavDropdown.Item>
                                        <NavDropdown.Item as={Link} to="/admin/holidays">Holidays</NavDropdown.Item>
                                        <NavDropdown.Item as={Link} to="/admin/rental-policy">Rental Policies</NavDropdown.Item>
                                        <NavDropdown.Item as={Link} to="/admin/bookings-calendar">Bookings Calendar</NavDropdown.Item>
                                        <NavDropdown.Item as={Link} to="/admin/manage-users">Manage Users</NavDropdown.Item>
                                        <NavDropdown.Item as={Link} to="/admin/discounts">Discounts</NavDropdown.Item>
                                        <NavDropdown.Item as={Link} to="/admin/tax-category">Tax Categories</NavDropdown.Item>
                                    </NavDropdown>
                                )}
                            </Nav>

                            <Nav>
                                {/* Cart Link: margin-end 3 to separate from Logout */}
                                <Nav.Link as={NavLink} to="/cart" className="cart-link me-3">
                                    <i className="fas fa-shopping-cart"></i>
                                    <span style={{ marginLeft: '5px' }}>Cart</span>
                                    {/* If the cart has items, show a small badge */}
                                    {cart.length > 0 && (
                                        <span className="cart-badge">{cart.length}</span>
                                    )}
                                </Nav.Link>

                                {/* Logout button */}
                                <Button variant="outline-light" onClick={logout}>
                                    Logout
                                </Button>

                                {/* Profile link */}
                                <Nav.Link as={NavLink} to="/profile" className="ms-2">
                                    Profile
                                </Nav.Link>
                            </Nav>
                        </>
                    ) : (
                        // If user is not logged in:
                        <Nav className="me-auto">
                            <Nav.Link as={NavLink} to="/login">Login</Nav.Link>
                            <Nav.Link as={NavLink} to="/register">Register</Nav.Link>
                        </Nav>
                    )}
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default Header;