// src/pages/CheckoutPage.js
import React, { useEffect, useState, useContext } from 'react';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import { useLocation, useNavigate } from 'react-router-dom';
import api from '../api';
import { CartContext } from '../context/CartContext';
import AuthContext from '../context/AuthContext';
import './CheckoutPage.css';

/**
 * Reuse the same discount logic from CartPage
 */
function calcDiscountedPrice(basePrice, discount) {
    if (!discount) return basePrice;
    const discountAmount = parseFloat(discount.discountAmount) || 0;
    const discountPercent = parseFloat(discount.discountPercent) || 0;
    let price = basePrice;
    if (discountPercent > 0) {
        price = price * (1 - discountPercent);
    } else if (discountAmount > 0) {
        price -= discountAmount;
        if (price < 0) price = 0;
    }
    return price;
}

const CheckoutPage = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const { cart: cartContext, loadCart } = useContext(CartContext);
    const { user } = useContext(AuthContext);

    // Extract from location.state (passed from Cart page or similar)
    const {
        notes = '',
        subtotalBeforeDiscount = 0,
        totalDiscount = 0,
        subtotalAfterDiscount = 0,
        taxAmount = 0,
        grandTotal = 0,
        activeDiscount = null,
        userTaxRate = 0.07,
        cart: cartFromState = []
    } = location.state || {};

    // Rental policy from backend
    const [policy, setPolicy] = useState(null);
    const [loadingPolicy, setLoadingPolicy] = useState(true);
    const [error, setError] = useState(null);

    // Payment method choice
    const [selectedMethod, setSelectedMethod] = useState('');
    // If user chooses partial deposit (pay only the fee now, remainder in store)
    const [payInStoreForRemainder, setPayInStoreForRemainder] = useState(false);

    // Customer info
    const [customerName, setCustomerName] = useState('');
    const [customerEmail, setCustomerEmail] = useState('');

    // Payment error
    const [paymentError, setPaymentError] = useState(null);

    // PayPal client ID
    const clientId = process.env.REACT_APP_PAYPAL_CLIENT_ID;

    // Load user info, etc.
    useEffect(() => {
        if (cartFromState.length === 0) {
            // Optionally redirect if cart is empty.
            // navigate('/cart');
        }
        if (user) {
            setCustomerName(`${user.firstName} ${user.lastName}`);
            setCustomerEmail(user.email);
        }
    }, [cartFromState, user]);

    // Fetch store rental policy
    useEffect(() => {
        const fetchPolicy = async () => {
            try {
                const resp = await api.get('/store/rental-policy');
                setPolicy(resp.data);
            } catch (err) {
                console.error('Failed to load rental policy:', err);
                setError('Failed to load rental policy.');
            } finally {
                setLoadingPolicy(false);
            }
        };
        fetchPolicy();
    }, []);

    if (loadingPolicy) return <p>Loading policy...</p>;
    if (error) return <p style={{ color: 'red' }}>{error}</p>;
    if (!policy) return <p>No policy found.</p>;

    // 1) Compute total discounted cost (pre-tax) for all cart items
    function getTotalDiscountedCost() {
        let total = 0;
        for (const ci of cartFromState) {
            const base = parseFloat(ci.unitPrice);
            const discounted = calcDiscountedPrice(base, activeDiscount);
            const qty = parseInt(ci.unitQuantity, 10) || 1;
            total += discounted * qty;
        }
        return total;
    }

    /**
     * 2) Compute the "effective" nonrefundable fee for the entire cart.
     *    - If `useNonrefundableFeePerItem` is true => multiply by number of distinct lines,
     *      rather than summing the 'quantity' of each line.
     *    - If there's exactly 1 line (and the quantity is 1) and the discounted price is less than the fee, fee = 0.
     */
    // 2) Compute the "effective" nonrefundable fee for the entire cart.
    function getEffectiveNonrefundableFee() {
        if (!policy.useNonrefundableFee) {
            return 0;
        }

        const baseFee = policy.nonrefundableFeeAmount;

        // LOGIC to calculate fee if NOT per line item
        if (!policy.useNonrefundableFeePerItem) {
            let fee = baseFee;

            // Corner case: if only 1 line with 1 qty and its discounted < fee => fee=0
            if (cartFromState.length === 1) {
                const singleItem = cartFromState[0];
                const base = parseFloat(singleItem.unitPrice);
                const discounted = calcDiscountedPrice(base, activeDiscount);
                const qty = parseInt(singleItem.unitQuantity, 10) || 1;

                if (qty === 1 && discounted < fee) {
                    fee = 0;
                }
                if (grandTotal < fee) {
                    fee = 0;
                }
            }
            return fee;
        }

        //
        // If policy is "per item," we multiply by the count of lines that do NOT ignore the fee
        let countEligibleLines = 0;
        for (const ci of cartFromState) {
            // If item has ignoreNonRefundableReservationFee = true, skip it
            if (!ci.ignoreNonRefundableReservationFee) {
                countEligibleLines++;
            }
        }

        let fee = baseFee * countEligibleLines;

        // Check to make sure discounted total is not less than fee, corner case
        if (cartFromState.length === 1) {
            const singleItem = cartFromState[0];
            const base = parseFloat(singleItem.unitPrice);
            const discounted = calcDiscountedPrice(base, activeDiscount);
            const qty = parseInt(singleItem.unitQuantity, 10) || 1;

            if (qty === 1 && discounted < fee) {
                fee = 0;
            }
            if (grandTotal < fee) {
                fee = 0;
            }
        }

        return fee;
    }

    /**
     * 3) Build line items for Lightspeed
     */
    function buildLightspeedItems() {
        // If "onlyCollectNonrefundableFeeOnline" => forced partial deposit if paying online
        const forceOnlyNRFeeOnline = (
            policy.useNonrefundableFee &&
            policy.onlyCollectNonrefundableFeeOnline &&
            policy.allowOnlinePayment
        );

        // If paying PayPal in full => leftover=0
        // EXCEPT if we are collecting only the NR fee or partial deposit
        let depositNow = 0;
        if (
            selectedMethod === 'PAYPAL'
            && policy.useNonrefundableFee
            && (payInStoreForRemainder || forceOnlyNRFeeOnline)
        ) {
            depositNow = getEffectiveNonrefundableFee();
        }

        // leftover with tax = total - depositNow
        const leftoverWithTax = Math.max(grandTotal - depositNow, 0);
        // total discounted cost of entire cart
        const totalDiscountedAll = getTotalDiscountedCost();

        // Distribute leftover across items proportionally
        return cartFromState.map(ci => {
            const base = parseFloat(ci.unitPrice);
            const quantity = parseInt(ci.unitQuantity, 10) || 1;
            const discountedPerUnit = calcDiscountedPrice(base, activeDiscount);

            let itemPretaxShare = 0;
            // leftoverPretaxCart:
            const leftoverPretaxCart = leftoverWithTax / (1 + userTaxRate);

            if (totalDiscountedAll > 0) {
                // proportion = (discounted price * quantity) / total discounted cost
                const itemDiscountedTotal = discountedPerUnit * quantity;
                const proportion = itemDiscountedTotal / totalDiscountedAll;
                itemPretaxShare = leftoverPretaxCart * proportion;
            }

            const perUnitPretax = itemPretaxShare / quantity;

            return {
                employeeID: ci.employeeID,
                shopID: ci.shopID,
                itemID: ci.itemID,
                unitPrice: perUnitPretax.toFixed(2),
                isLayaway: "true",
                customerID: ci.customerId,
                unitQuantity: ci.unitQuantity.toString(),
                startDate: ci.startDate,
                endDate: ci.endDate,
                imageUrl: ci.imageUrl,
                itemDescription: ci.itemDescription,
                customSku: ci.customSku
            };
        });
    }

    /**
     * 4) In-Store Payment
     */
    const handleInStoreCheckout = async () => {
        setPaymentError(null);

        if (selectedMethod !== 'IN_STORE') {
            setPaymentError("Please select 'In-Store' method.");
            return;
        }

        try {
            const lightspeedItems = buildLightspeedItems();

            const nonrefundableFeeCharged = getEffectiveNonrefundableFee();

            const payload = {
                paymentMethod: 'IN_STORE',
                currency: 'USD',
                payInStoreForRemainder: false,
                customerName,
                customerEmail,
                notes,
                subtotalBeforeDiscount,
                totalDiscount,
                subtotalAfterDiscount,
                taxAmount,
                grandTotal,
                userTaxRate,
                lightspeedItems,
                nonrefundableFeeCharged
            };

            const resp = await api.post('/checkout', payload);
            if (!resp.data.success) {
                setPaymentError(
                    "We couldn't complete the in-store checkout. " +
                    "Please try again or contact us. (Details: " + resp.data.message + ")"
                );
                return;
            }
            // success => clear cart, go to confirmation
            loadCart([]);
            navigate('/confirmation');
        } catch (err) {
            console.error("In-store checkout error:", err);
            setPaymentError(
                "Your in-store checkout could not be completed. Please try again or contact support."
            );
        }
    };

    /**
     * 5) PayPal Buttons
     */
    const renderPayPalButtons = () => {
        const forceOnlyNRFeeOnline = (
            policy.useNonrefundableFee &&
            policy.onlyCollectNonrefundableFeeOnline &&
            policy.allowOnlinePayment
        );

        // If user must pay only the NR fee online, or they choose partial deposit
        // then we charge that "effective" NR fee. Otherwise we do the full grandTotal.
        let amountToCharge = grandTotal;
        if (
            policy.useNonrefundableFee &&
            (forceOnlyNRFeeOnline || (policy.allowInStorePayment && payInStoreForRemainder))
        ) {
            amountToCharge = getEffectiveNonrefundableFee();
        }

        const lightspeedItems = buildLightspeedItems();

        return (
            <PayPalScriptProvider options={{ 'client-id': clientId }}>
                <PayPalButtons
                    style={{ layout: 'vertical' }}
                    createOrder={(data, actions) => {
                        return actions.order.create({
                            purchase_units: [{
                                amount: { value: amountToCharge.toFixed(2) }
                            }]
                        });
                    }}
                    onApprove={async (data, actions) => {
                        setPaymentError(null);
                        try {
                            const details = await actions.order.capture();
                            console.log('PayPal capture details:', details);

                            const captureId = details.purchase_units[0].payments.captures[0].id;

                            const nonrefundableFeeCharged = getEffectiveNonrefundableFee();

                            const payload = {
                                paymentMethod: 'PAYPAL',
                                currency: 'USD',
                                payInStoreForRemainder,
                                customerName,
                                customerEmail,
                                notes,
                                paypalCaptureId: captureId,
                                subtotalBeforeDiscount,
                                totalDiscount,
                                subtotalAfterDiscount,
                                taxAmount,
                                grandTotal,
                                userTaxRate,
                                lightspeedItems,
                                nonrefundableFeeCharged
                            };

                            const resp = await api.post('/checkout', payload);
                            if (!resp.data.success) {
                                setPaymentError(
                                    "We couldn't validate your payment after PayPal authorization. " +
                                    "Please try again or use a different payment method. (Details: " + resp.data.message + ")"
                                );
                                return;
                            }

                            loadCart([]);
                            navigate('/confirmation');
                        } catch (finalizeErr) {
                            console.error('PayPal finalize error:', finalizeErr);
                            setPaymentError(
                                "Your payment was authorized but we couldn't finalize. " +
                                "Please contact support or try again."
                            );
                        }
                    }}
                    onCancel={() => {
                        console.warn('PayPal payment canceled.');
                        setPaymentError(
                            "Payment was canceled. You may select a different method or try again."
                        );
                    }}
                    onError={(err) => {
                        console.error('PayPal payment error:', err);
                        let message = "We couldn't validate your payment. Please verify your card or PayPal account and try again.";

                        if (typeof err === 'object' && err.message) {
                            if (err.message.includes("DECLINED")) {
                                message =
                                    "Your payment was declined by your bank or card issuer. " +
                                    "Please try another payment method.";
                            } else if (
                                err.message.includes("VALIDATION_ERROR") ||
                                err.message.includes("OAS_VALIDATION_ERROR")
                            ) {
                                message =
                                    "There was an issue validating your credit card details. " +
                                    "Please double-check and try again.";
                            }
                        }
                        setPaymentError(message);
                    }}
                />
            </PayPalScriptProvider>
        );
    };

    // Hide In-Store if onlyCollectNonrefundableFeeOnline is true
    const hideInStoreOption = policy.onlyCollectNonrefundableFeeOnline;

    // For displaying in the summary, compute the “effective” fee here
    const effectiveFee = getEffectiveNonrefundableFee();

    return (
        <div className="checkout-container">
            <h1>Checkout</h1>

            {paymentError && (
                <div className="payment-error-box">
                    {paymentError}
                </div>
            )}

            {/* Final Totals */}
            <div className="checkout-totals">
                <p>
                    <span>Subtotal (Before Discount):</span>
                    <span>${subtotalBeforeDiscount.toFixed(2)}</span>
                </p>
                {totalDiscount > 0 && (
                    <p>
                        <span>Discount:</span>
                        <span>-${totalDiscount.toFixed(2)}</span>
                    </p>
                )}
                <p>
                    <span>Subtotal (After Discount):</span>
                    <span>${subtotalAfterDiscount.toFixed(2)}</span>
                </p>
                <p>
                    <span>Tax:</span>
                    <span>${taxAmount.toFixed(2)}</span>
                </p>
                <p>
                    <strong>Grand Total:</strong>
                    <strong>${grandTotal.toFixed(2)}</strong>
                </p>

                {/* Show nonrefundable fee in summary if > 0 */}
                {policy.useNonrefundableFee && effectiveFee > 0 && (
                    <div
                        className="cart-summary-row"
                        style={{marginTop: '0.75em', fontWeight: 'bold'}}
                    >
                        <span>Reservation Deposit:</span>
                        <span>${effectiveFee.toFixed(2)}</span>
                    </div>
                )}
            </div>

            <div className="payment-method-section">
                <h2>Select Payment Method</h2>

                {policy.onlyCollectNonrefundableFeeOnline && (
                    <p style={{color: 'blue', fontWeight: 'bold'}}>
                        This store only collects the reservation deposit online.
                        You will pay the remaining balance in-store upon pickup.
                    </p>
                )}

                {/* Online Payment (PayPal / Credit) */}
                {policy.allowOnlinePayment && (
                    <>
                        <label>
                            <input
                                type="radio"
                                name="paymentMethod"
                                value="PAYPAL"
                                checked={selectedMethod === 'PAYPAL'}
                                onChange={() => setSelectedMethod('PAYPAL')}
                            />
                            Pay with Credit, Debit, or PayPal
                        </label>

                        {/* Partial deposit option if useNonrefundableFee + In-Store is allowed + not forced-only */}
                        {(selectedMethod === 'PAYPAL'
                            && policy.useNonrefundableFee
                            && policy.allowInStorePayment
                            && !policy.onlyCollectNonrefundableFeeOnline) && (
                            <div className="payment-method-suboption">
                                <label>
                                    <input
                                        type="checkbox"
                                        checked={payInStoreForRemainder}
                                        onChange={(e) => setPayInStoreForRemainder(e.target.checked)}
                                    />
                                    Pay only the reservation deposit now, pay remainder in store
                                </label>
                                {payInStoreForRemainder && effectiveFee > 0 && (
                                    <p style={{color: 'green'}}>
                                        Reservation Deposit = ${effectiveFee.toFixed(2)} <br/>
                                        You'll pay the rest in-store at pickup.
                                    </p>
                                )}
                                {payInStoreForRemainder && effectiveFee === 0 && (
                                    <p style={{color: 'green'}}>
                                        No reservation deposit is required for your item(s).<br/>
                                        You will pay the full amount in-store.
                                    </p>
                                )}
                            </div>
                        )}
                    </>
                )}

                {/* In-Store Payment (only show if not hidden) */}
                {!hideInStoreOption && policy.allowInStorePayment && (
                    <div style={{marginTop: '1em'}}>
                        <label>
                            <input
                                type="radio"
                                name="paymentMethod"
                                value="IN_STORE"
                                checked={selectedMethod === 'IN_STORE'}
                                onChange={() => {
                                    setSelectedMethod('IN_STORE');
                                    setPayInStoreForRemainder(false);
                                }}
                            />
                            Pay In-Store
                        </label>
                        {policy.useNonrefundableFee && selectedMethod === 'IN_STORE' && effectiveFee > 0 && (
                            <p style={{color: 'green', marginLeft: '2em'}}>
                                Note: The reservation deposit of $
                                {effectiveFee.toFixed(2)} is nonrefundable if you cancel
                                within {policy.nonrefundableFeeWindowHours} hours of your pickup date.
                            </p>
                        )}
                        {policy.useNonrefundableFee && selectedMethod === 'IN_STORE' && effectiveFee === 0 && (
                            <p style={{color: 'green', marginLeft: '2em'}}>
                                No reservation deposit is charged for your item(s).
                            </p>
                        )}
                    </div>
                )}

                {/* If NO payment methods are available */}
                {!policy.allowOnlinePayment && !policy.allowInStorePayment && (
                    <p style={{color: 'red'}}>No payment methods currently available.</p>
                )}
            </div>

            <div className="checkout-userinfo">
                <label>Full Name:</label><br/>
                <input
                    type="text"
                    value={customerName}
                    readOnly
                    style={{width: '100%', marginBottom: '1em'}}
                />

                <label>Email:</label><br/>
                <input
                    type="email"
                    value={customerEmail}
                    readOnly
                    style={{width: '100%'}}
                />
            </div>

            {/* Render PayPal buttons if user selected PAYPAL */}
            {selectedMethod === 'PAYPAL' && (
                <div className="paypal-button-container">
                    {renderPayPalButtons()}
                </div>
            )}

            <div className="button-container">
                {/* Render In-Store button if user selected IN_STORE */}
                {selectedMethod === 'IN_STORE' && (
                    <button className="instore-button" onClick={handleInStoreCheckout}>
                        Proceed with Reservation
                    </button>
                )}

                {/* Back to cart button */}
                <button
                    onClick={() => navigate(`/cart`)}
                    className="go-back-to-cart-btn"
                >
                    Back to Cart
                </button>
            </div>
        </div>
    )
        ;
};

export default CheckoutPage;