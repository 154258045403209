// src/pages/VideoProductionPage.js
import React, { useEffect, useState } from 'react';
import ItemCard from '../components/ItemCard';
import api from '../api';
import './VideoProductionPage.css';

const VIDEO_CATEGORIES = [239, 243];
// 239 = Lighting, 243 = Video/Audio Accessories, etc.

const VideoProductionPage = () => {
    const [items, setItems] = useState([]);
    const [searchTerm, setSearchTerm] = useState(''); // NEW
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchVideoItems = async () => {
            try {
                const requests = VIDEO_CATEGORIES.map(catId => api.get(`/items/category/${catId}`));
                const responses = await Promise.all(requests);
                const combined = responses.flatMap(res => res.data);

                // filter out hidden items or duplicates
                const seen = new Map();
                for (const i of combined) {
                    if (!seen.has(i.itemId) && !i.hidden) {
                        seen.set(i.itemId, i);
                    }
                }
                setItems(Array.from(seen.values()));
            } catch (err) {
                setError('Failed to fetch video production items');
            } finally {
                setLoading(false);
            }
        };
        fetchVideoItems();
    }, []);

    // -- Filter logic --
    const sTerm = searchTerm.toLowerCase().trim();
    const finalFilteredItems = items.filter(item => {
        if (!sTerm) return true;

        const desc = (item.description || '').toLowerCase();
        const upc = (item.upc || '').toLowerCase();
        const ean = (item.ean || '').toLowerCase();
        const customSku = (item.customSku || '').toLowerCase();
        const manufacturerSku = (item.manufacturerSku || '').toLowerCase();

        return (
            desc.includes(sTerm) ||
            upc.includes(sTerm) ||
            ean.includes(sTerm) ||
            customSku.includes(sTerm) ||
            manufacturerSku.includes(sTerm)
        );
    });

    if (loading) return <p>Loading...</p>;
    if (error) return <p>{error}</p>;

    return (
        <div className="video-production-container">
            <div className="video-production-header">
                <h1>Video/Audio Production and Accessories</h1>
                <input
                    type="text"
                    placeholder="Description or SKU..."
                    className="video-production-search-input"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
            </div>

            <div className="items-grid">
                {finalFilteredItems.map(item => (
                    <ItemCard key={item.itemId} item={item} />
                ))}
            </div>
        </div>
    );
};

export default VideoProductionPage;