// src/pages/ProjectorPage.js
import React, { useEffect, useState } from 'react';
import ItemCard from '../components/ItemCard';
import api from '../api';
import './ProjectorPage.css';

const ProjectorPage = () => {
    const [projectorItems, setProjectorItems] = useState([]);
    const [searchTerm, setSearchTerm] = useState(''); // NEW
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchProjectorAccessoriesItems = async () => {
            try {
                const response = await api.get('/items/category/237');
                console.log("Fetched Projectors and Accessories Items:", response.data);
                setProjectorItems(response.data);
            } catch (error) {
                setError('Failed to fetch Projectors and Accessories items');
                console.error(error);
            } finally {
                setLoading(false);
            }
        };

        fetchProjectorAccessoriesItems();
    }, []);

    // -- Filter logic (description, upc, ean, etc.) --
    const sTerm = searchTerm.toLowerCase().trim();
    const finalFilteredItems = projectorItems
        .filter(item => !item.hidden)
        .filter(item => {
            if (!sTerm) return true;

            const desc = (item.description || '').toLowerCase();
            const upc = (item.upc || '').toLowerCase();
            const ean = (item.ean || '').toLowerCase();
            const customSku = (item.customSku || '').toLowerCase();
            const manufacturerSku = (item.manufacturerSku || '').toLowerCase();

            return (
                desc.includes(sTerm) ||
                upc.includes(sTerm) ||
                ean.includes(sTerm) ||
                customSku.includes(sTerm) ||
                manufacturerSku.includes(sTerm)
            );
        });

    if (loading) return <p>Loading...</p>;
    if (error) return <p>{error}</p>;

    return (
        <div className="projector-page-container">
            <div className="projector-header">
                <h1>Projectors and Accessories</h1>
                <input
                    type="text"
                    placeholder="Description or SKU..."
                    className="projector-search-input"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
            </div>

            <div className="items-grid">
                {finalFilteredItems.map(item => (
                    <ItemCard key={item.itemId} item={item} />
                ))}
            </div>
        </div>
    );
};

export default ProjectorPage;