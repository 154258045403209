// src/pages/AdminRentalPolicyPage.js
import React, { useEffect, useState } from 'react';
import api from '../api';
import './AdminRentalPolicyPage.css';

const AdminRentalPolicyPage = () => {
    const [rentalPolicy, setRentalPolicy] = useState({
        discountClosedDaysAndHolidays: false,
        allowOnlinePayment: false,
        allowInStorePayment: false,
        useNonrefundableFee: false,
        useNonrefundableFeePerItem: false,
        nonrefundableFeeAmount: 0,
        nonrefundableFeeWindowHours: 24,
        onlyCollectNonrefundableFeeOnline: false
    });

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Fetch the existing rental policy
    useEffect(() => {
        const fetchRentalPolicy = async () => {
            try {
                const response = await api.get('/store/rental-policy');
                setRentalPolicy(response.data);
            } catch (err) {
                console.error('Failed to fetch rental policy:', err);
                setError('Could not load rental policy.');
            } finally {
                setLoading(false);
            }
        };
        fetchRentalPolicy();
    }, []);

    // Handle ANY checkbox toggles
    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setRentalPolicy((prev) => {
            const newPolicy = { ...prev, [name]: checked };

            // If any of these are false => onlyCollectNonrefundableFeeOnline = false
            if (
                !newPolicy.useNonrefundableFee ||
                !newPolicy.allowOnlinePayment ||
                !newPolicy.allowInStorePayment
            ) {
                newPolicy.onlyCollectNonrefundableFeeOnline = false;
            }

            // If useNonrefundableFee is turned off => set useNonrefundableFeePerItem to false
            if (!newPolicy.useNonrefundableFee) {
                newPolicy.useNonrefundableFeePerItem = false;
            }

            return newPolicy;
        });
    };

    // Handle numeric changes
    const handleNumberChange = (e) => {
        const { name, value } = e.target;
        setRentalPolicy((prev) => ({
            ...prev,
            [name]: parseFloat(value),
        }));
    };

    // Handle integer changes
    const handleIntegerChange = (e) => {
        const { name, value } = e.target;
        setRentalPolicy((prev) => ({
            ...prev,
            [name]: parseInt(value, 10),
        }));
    };

    // Save the policy
    const handleSave = async () => {
        try {
            const response = await api.put('/store/rental-policy', rentalPolicy);
            setRentalPolicy(response.data);
            alert('Rental policy updated successfully!');
        } catch (error) {
            console.error('Failed to update rental policy:', error);
            alert('Failed to update rental policy.');
        }
    };

    if (loading) return <p>Loading...</p>;
    if (error) return <p style={{ color: 'red' }}>{error}</p>;

    // onlyCollectNonrefundableFeeOnline is disabled if ANY of these are false
    // i.e. we require all to be true in order to enable it
    const disableOnlyNRFeeOnline = !(
        rentalPolicy.useNonrefundableFee &&
        rentalPolicy.allowOnlinePayment &&
        rentalPolicy.allowInStorePayment
    );

    return (
        <div className="admin-rental-policy-container">
            <h1>Store Rental Policies</h1>

            <div className="policy-row">
                <label>
                    <input
                        type="checkbox"
                        name="discountClosedDaysAndHolidays"
                        checked={rentalPolicy.discountClosedDaysAndHolidays}
                        onChange={handleCheckboxChange}
                    />
                    <span> Discount Closed Days & Holidays</span>
                </label>
            </div>

            <div className="policy-row">
                <label>
                    <input
                        type="checkbox"
                        name="allowOnlinePayment"
                        checked={rentalPolicy.allowOnlinePayment}
                        onChange={handleCheckboxChange}
                    />
                    <span> Allow Online Payment</span>
                </label>
            </div>

            <div className="policy-row">
                <label>
                    <input
                        type="checkbox"
                        name="allowInStorePayment"
                        checked={rentalPolicy.allowInStorePayment}
                        onChange={handleCheckboxChange}
                    />
                    <span> Allow In-Store Payment</span>
                </label>
            </div>

            <div className="policy-row">
                <label>
                    <input
                        type="checkbox"
                        name="useNonrefundableFee"
                        checked={rentalPolicy.useNonrefundableFee}
                        onChange={handleCheckboxChange}
                    />
                    <span> Use Reservation Deposit</span>
                </label>
            </div>

            <div className="policy-row">
                <label>
                    <input
                        type="checkbox"
                        name="useNonrefundableFeePerItem"
                        checked={rentalPolicy.useNonrefundableFeePerItem}
                        onChange={handleCheckboxChange}
                        disabled={!rentalPolicy.useNonrefundableFee}
                    />
                    <span> Use Reservation Deposit Per Item</span>
                </label>
            </div>

            <div className="policy-row">
                <label>
                    <input
                        type="checkbox"
                        name="onlyCollectNonrefundableFeeOnline"
                        checked={rentalPolicy.onlyCollectNonrefundableFeeOnline}
                        onChange={handleCheckboxChange}
                        disabled={disableOnlyNRFeeOnline}
                    />
                    <span> Only collect reservation deposit online (remainder in-store)</span>
                </label>
            </div>

            <div className="policy-row">
                <label className="policy-label">
                    Reservation Deposit Amount:
                </label>
                <input
                    type="number"
                    step="0.01"
                    name="nonrefundableFeeAmount"
                    value={rentalPolicy.nonrefundableFeeAmount}
                    onChange={handleNumberChange}
                    className="policy-input"
                    disabled={!rentalPolicy.useNonrefundableFee}
                />
            </div>

            <div className="policy-row">
                <label className="policy-label">
                    Reservation Deposit Window (hours):
                </label>
                <input
                    type="number"
                    name="nonrefundableFeeWindowHours"
                    value={rentalPolicy.nonrefundableFeeWindowHours}
                    onChange={handleIntegerChange}
                    className="policy-input"
                    disabled={!rentalPolicy.useNonrefundableFee}
                />
            </div>

            <button className="policy-save-button" onClick={handleSave}>
                Save Policy
            </button>
        </div>
    );
};

export default AdminRentalPolicyPage;